/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import "semantic-ui-css/semantic.min.css";

import { createMedia } from "@artsy/fresnel";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";

import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";

import header from "./header.jpeg";
import map from "./map.jpg";

const ImgContainer = styled.div`
  background-image: url(${header});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
`;

const Shade = styled.div`
  background-color: black;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  position: absolute;
`

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text
  style={{
    paddingTop: mobile ? '2em' : 0,
  }}>
    <Header
      as="h1"
      content="Mandu Movers"
      inverted
      style={{
        fontSize: mobile ? "2em" : "4em",
        fontWeight: "normal",
        marginBottom: 0,
        marginTop: mobile ? "1.5em" : "3em",
      }}
    />
    <Header
      as="h2"
      content="Moving you to your new home."
      inverted
      style={{
        fontSize: mobile ? "1.5em" : "1.7em",
        fontWeight: "normal",
        marginTop: mobile ? "0.5em" : "1.5em",
      }}
    />
    <a href="mailto:movers@mandumovers.com">
      <Button primary size="huge">
        Request a quote
        <Icon name="right arrow" />
      </Button>
    </a>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
};

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Media greaterThan="mobile">
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <ImgContainer>
            <Shade />
            <Segment
              inverted
              textAlign="center"
              style={{
                minHeight: 700,
                padding: "1 em 0em",
                background: "unset",
                backgroundColor: "transparent",
              }}
              vertical
            >
              <Menu
                fixed={fixed ? "top" : null}
                inverted={!fixed}
                pointing={!fixed}
                secondary={!fixed}
                size="large"
              >
                <Container>
                  <Menu.Item as="a" active>
                    Home
                  </Menu.Item>
                  <Menu.Item position="right">
                    <a href="mailto:movers@mandumovers.com">
                      <Button as="a" inverted={!fixed} primary={fixed}>
                        Request a quote
                        <Icon name="right arrow" />
                      </Button>
                    </a>
                  </Menu.Item>
                </Container>
              </Menu>
              <HomepageHeading />
            </Segment>
          </ImgContainer>
        </Visibility>

        {children}
      </Media>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Media as={Sidebar.Pushable} at="mobile">
        <ImgContainer>
          <Shade />
          <Segment
            inverted
            textAlign="center"
            style={{ backgroundColor: 'transparent', minHeight: 350, padding: "1em 0em"}}
            vertical
          >
            <HomepageHeading mobile />
          </Segment>
        </ImgContainer>

        {children}
      </Media>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: "8em 0em" }} vertical>
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              We help move you to your new destination
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              We are located in the Pacific Northwest. We are a small family
              owned moving company. Our mission is to help move you to the new
              place you now call home.
            </p>
            <Header as="h3" style={{ fontSize: "2em" }}>
              We provide world-class services at a low cost
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              We utilize cost saving methods without sacrificing any quality or
              service to you, the customer. We are guaranteed to be priced
              better than a reputable local competitor!
            </p>
            <Header as="h3" style={{ fontSize: "2em" }}>
              We are boundless
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              We will help you get to your next destination in life. A new
              chapter starts now with Mandu Movers. We will help move you no
              matter where you decide to relocate.
            </p>
          </Grid.Column>
          <Grid.Column floated="right" width={6}>
            <Grid.Row>
              <Image bordered rounded size="large" src={map} />
            </Grid.Row>
            <Grid.Row>
              <p style={{ fontSize: "1.33em", padding: "1em 0em" }}>
                We are proudly American owned and operated.
              </p>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <a href="mailto:movers@mandumovers.com">
              <Button size="huge">
                Request a quote <Icon name="right arrow" />
              </Button>
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: "0em" }} vertical>
      <Grid celled="internally" columns="equal" stackable>
        <Grid.Row textAlign="center">
          <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              "We have many vehicles to move your things!
              <br /> Camrys, Civics, and Corollas!"
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              <b>Mangu</b> - Mover and Scheduler
            </p>
          </Grid.Column>
          <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              "Let me use my extremely large calves to move your things!"
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              <b>Andu</b> - Lead Mover
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: "8em 0em" }} vertical>
      <Container text>
        <Header as="h3" style={{ fontSize: "2em" }}>
          Customer satisfaction is our number one priority
        </Header>
        <p style={{ fontSize: "1.33em" }}>
          We will not rest until you are fully satisfied. Here at Mandu Movers,
          we understand how stressful your move is. Our professional staff is
          here to help you ever step of the way. From planning and packing to
          delivery and disassembly.
        </p>
        <div style={{ textAlign: "center" }}>
          <a href="mailto:movers@mandumovers.com">
            <Button size="huge">
              Request a quote <Icon name="right arrow" />
            </Button>
          </a>
        </div>

        <Divider
          as="h4"
          className="header"
          horizontal
          style={{ margin: "3em 0em", textTransform: "uppercase" }}
        >
          <a href="#">Customer Testimonials</a>
        </Divider>

        <Header as="h3" style={{ fontSize: "2em" }}>
          Extremely Happy and Relieved
        </Header>
        <p style={{ fontSize: "1.33em" }}>
          Mandu Movers helped me move my car from Portland to Austin. What a
          relief! I lived in the city for several years and had completely
          forgotten how to drive. Without Mandu Movers I would have been
          hopeless! Mandu Movers were a very cost competitative option, and much
          cheaper than a comparable moving service. They even delivered in half
          the time! And man, that one dude had massive calves.
        </p>
        <p style={{ fontSize: "1.33em" }}>
          <b>Dan</b> - Portland to Austin
        </p>
      </Container>
    </Segment>

    <Segment inverted vertical style={{ padding: "5em 0em" }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row style={{ justifyContent: "center" }}>
            <Grid.Column width={4}>
              <Header inverted as="h4" content="About" />
              <List link inverted>
                <List.Item as="a">
                  <a href="mailto:support@mandumovers.com">Contact Us</a>
                </List.Item>
                <List.Item as="a">
                  <a href="mailto:movers@mandumovers.com">Request a quote</a>
                </List.Item>
              </List>
            </Grid.Column>

            <Grid.Column width={4}>
              <Header as="h4" inverted>
                Copyright 2021
              </Header>
              <p>Made with ♡ and React by Mangu</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
);

export default HomepageLayout;
